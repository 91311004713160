/* Color Theme Swatches in Hex */
@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Raleway', Arial, Helvetica, sans-serif; }

.App {
  max-width: 1200px;
  margin: 0 auto;
  background: white;
  box-shadow: 0px 0px 5px 1px #f2cc0f; }

body {
  background: linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px, linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px, linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px, linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px, linear-gradient(90deg, #1b1b1b 10px, transparent 10px), linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
  background-color: #131313;
  background-size: 20px 20px; }

.navigation {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2; }
  .navigation__fixed {
    padding: 1rem;
    max-width: 1200px;
    background: #3b3c40;
    margin: 0 auto;
    color: white; }
    .navigation__fixed__logo {
      font-weight: bold;
      margin-left: 0.5rem; }
    .navigation__fixed__links {
      list-style: none;
      display: none; }
      @media screen and (min-width: 576px) {
        .navigation__fixed__links {
          display: inline-block; } }
      .navigation__fixed__links a {
        display: inline-block;
        margin-left: 1.5rem;
        text-decoration: none;
        font-weight: bold;
        color: white;
        transition: all 0.35s ease-out; }
        .navigation__fixed__links a:hover {
          color: #f2cc0f; }
    .navigation__fixed__menu-bar {
      float: right;
      clear: both;
      cursor: pointer; }
      @media screen and (min-width: 576px) {
        .navigation__fixed__menu-bar {
          display: none; } }
      .navigation__fixed__menu-bar:hover div {
        background: #f2cc0f; }
      .navigation__fixed__menu-bar div {
        width: 35px;
        height: 5px;
        background: white;
        border-radius: 10px;
        transition: all 0.35s ease-out; }
      .navigation__fixed__menu-bar #two {
        margin-top: 3px; }
      .navigation__fixed__menu-bar #three {
        margin-top: 3px; }

.toggle-menu {
  background: rgba(59, 60, 64, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.7s ease-out;
  transform: translateY(-1500px); }
  .toggle-menu__links {
    list-style: none; }
    .toggle-menu__links a {
      text-decoration: none;
      color: white;
      transition: color 0.35s ease-out; }
      .toggle-menu__links a:hover {
        color: #f2cc0f; }
      .toggle-menu__links a li {
        margin: 1rem;
        font-size: 1.5rem;
        text-align: center; }

.reveal {
  transform: translateY(0); }

.home {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  background: url("./images/home.jpg");
  background-color: #3b3c40;
  background-blend-mode: overlay;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  .home p {
    font-size: 1.3rem;
    margin-top: 0.5rem; }
  .home__links {
    margin-top: 0.5rem; }
    .home__links a {
      margin: 0.5rem;
      color: #fff; }
      .home__links a i {
        transition: all 0.35s ease-out; }
        .home__links a i:hover {
          transform: scale(1.1);
          color: #f2cc0f; }

.about-me {
  padding: 3rem 1rem 3rem 1rem;
  max-width: 1000px;
  margin: 0 auto;
  color: #3b3c40; }
  .about-me__header {
    text-align: center; }
  .about-me__summary {
    margin: 0 auto;
    margin-top: 2rem;
    max-width: 600px;
    text-align: center; }
  .about-me__skills {
    display: grid;
    margin-top: 2rem;
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 2rem; }
    @media screen and (min-width: 576px) {
      .about-me__skills {
        grid-template-columns: repeat(2, 1fr); } }
    @media screen and (min-width: 768px) {
      .about-me__skills {
        grid-template-columns: repeat(3, 1fr); } }
    .about-me__skills ul {
      margin-top: 1rem; }

.projects {
  padding-bottom: 2rem;
  color: #3b3c40; }
  .projects__header {
    text-align: center; }
  .projects__card-group {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .projects__card-group__card-item {
      box-shadow: 0px 0px 5px 1px #3b3c40;
      margin: 1rem;
      max-width: 350px;
      height: 200px;
      transition: all 0.35s ease-out;
      background: #3b3c40; }
      .projects__card-group__card-item:hover {
        transform: translateY(-10px);
        box-shadow: 0px 10px 10px 1px #3b3c40; }
        .projects__card-group__card-item:hover span {
          opacity: 1; }
        .projects__card-group__card-item:hover img {
          opacity: 0.2; }
      .projects__card-group__card-item span {
        position: absolute;
        margin-top: 5.5rem;
        margin-left: 1rem;
        font-weight: bold;
        color: white;
        opacity: 0;
        transition: all 0.35s ease-out; }
      .projects__card-group__card-item img {
        width: 100%;
        height: 100%;
        transition: all 0.35s ease-out; }

.contacts {
  background: #125668;
  text-align: center;
  padding-top: 2rem;
  color: white; }
  .contacts__lead {
    margin-top: 1rem;
    font-size: 1.3rem; }
  .contacts__mail {
    margin-top: 0.5rem;
    font-size: 1.15rem; }
    .contacts__mail span {
      font-weight: bold; }
  .contacts__icons {
    margin-top: 1rem; }
  .contacts__copyright {
    background: #3b3c40;
    padding: 1rem;
    margin-top: 2rem;
    font-size: 1.15rem;
    font-weight: bold;
    color: white; }
  .contacts__links {
    margin-top: 1rem; }
    .contacts__links a {
      margin: 0.5rem;
      color: #fff; }
      .contacts__links a i {
        transition: all 0.35s ease-out; }
        .contacts__links a i:hover {
          transform: scale(1.1);
          color: #f2cc0f; }

.divide {
  background-color: #f2cc0f;
  height: 5px; }

.about-project {
  padding: 6rem 1rem 3rem 1rem;
  display: grid;
  color: #3b3c40;
  gap: 2rem;
  max-width: 600px;
  margin: 0 auto; }
  @media screen and (min-width: 992px) {
    .about-project {
      max-width: 1200px;
      grid-template-columns: 1fr 1fr; } }
  .about-project__image {
    box-shadow: 0px 0px 5px 1px #3b3c40;
    width: 100%; }
  .about-project__details p {
    margin-top: 1.5rem; }
  .about-project__details__links {
    margin-top: 1.5rem; }
    .about-project__details__links a {
      display: block;
      border: 3px solid #3b3c40;
      width: 150px;
      text-align: center;
      padding: 0.7rem 0 0.7rem 0;
      text-decoration: none;
      background: #3b3c40;
      color: white;
      font-weight: bold;
      transition: all 0.35s ease-out; }
      .about-project__details__links a:hover {
        background: white;
        color: #3b3c40; }
      .about-project__details__links a:nth-child(2) {
        margin-top: 0.5rem; }

.project-navigation {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2; }
  .project-navigation__fixed {
    padding: 1rem;
    max-width: 1200px;
    background: #3b3c40;
    margin: 0 auto;
    color: white; }
    .project-navigation__fixed__logo {
      font-weight: bold;
      margin-left: 0.5rem; }
    .project-navigation__fixed__links {
      display: inline-block;
      list-style: none; }
      .project-navigation__fixed__links .route-link {
        text-decoration: none; }
        .project-navigation__fixed__links .route-link li {
          margin-left: 1.5rem;
          text-decoration: none;
          font-weight: bold;
          color: white;
          transition: all 0.35s ease-out; }
          .project-navigation__fixed__links .route-link li:hover {
            color: #f2cc0f; }

.error {
  min-height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center; }
  .error__emoji {
    font-weight: bold;
    color: #3b3c40;
    font-size: 3rem; }
    @media screen and (min-width: 576px) {
      .error__emoji {
        font-size: 5rem; } }
  .error__message {
    margin-left: 1rem; }
    @media screen and (min-width: 576px) {
      .error__message {
        font-size: 1.3rem; } }
    .error__message__link {
      text-decoration: none;
      transition: all 0.35s ease-out;
      margin-left: 0.5rem; }
      .error__message__link:hover {
        color: #f2cc0f; }
